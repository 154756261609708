import React, { Component } from 'react';


class Header extends Component {

  // render nav
  render() {
    return (
      <div>
        {/* <div id='topNav'>

        </div> */}
           <nav id='navBody' className="uk-navbar-container uk-margin uk-margin-auto uk-margin-auto-vertical" uk-navbar='true' >
                <div className="uk-navbar-center uk-margin-auto-vertical ">
                    <img id='logoMobile' className="" src="../images/Mayfly-Woodworks_Logo_White.png" alt='MayFly tables logo'></img>
                </div>
            </nav>
      </div>
    );
  }
}


export default Header;