import React, { Component } from 'react';
import Footer from '../Components/Footer/Footer.js'
// import AliceCarousel from 'react-alice-carousel';
import "react-alice-carousel/lib/alice-carousel.css";
import axios from 'axios'
import { ToastContainer,toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



class Home extends Component {

   

    state = {
        name: '',
        email: '',
        subject: '',
        subject2: '',
        message: '',
       }
    
       notify = () => {
        toast.success("Your Email Has Been Sent !", {
          position: toast.POSITION.BOTTOM_CENTER
        });
      };
      notifyError = () => {
        toast.error("You Must Fill Out All Forms !", {
          position: toast.POSITION.BOTTOM_CENTER
        });
      };
     
      
    
       sendEmail = event => {
        //  event.preventDefault()
         if(this.state.name === ''){
           this.notifyError()
         }else if(this.state.email === ''){
          this.notifyError()
         }else if(this.state.subject === ''){
          this.notifyError()
         }else if(this.state.message === ''){
          this.notifyError()
         }
         
         else {
          axios.post("https://www.mayflywoodworks.com/sendEmail", {
            email: this.state.email,
             name: this.state.name,
             subject: this.state.subject,             
             message: this.state.message
           })
             .then((response) => {
              console.log(response)
             })
             .catch((err) => {
               console.log(err)
             })           
             this.notify()
             this.emptyFields()
             
         }
    
    
       };
    
       handleInputChange = event => {
         const { name, value } = event.target;
         this.setState({
           [name]: value
         });
       };
    
       emptyFields = () => {
         this.setState({
           name: '',
           email: '',
           subject: '',           
           message: ''
         })
       }


  thumbItem = (item, i) => (
    <span key={item} onClick={() => this.Carousel._onDotClick(i)}>* </span>
  )



  // render nav
  render() {
   
    return (
      <div>
          <div id='websiteCarousel' className='' >
            <div className='' uk-grid='true' className=' '>
                <div className='uk-width-2-3 '>
                   <div className='uk-container  textHead'>
                        <img id='headerLogo'  className='uk-margin-large-bottom ' style={{width:'100px', height:'100px', marginLeft:'30px'}} src='../images/Mayfly_Icon Logo.png'></img>
                        <h1 className='uk-visible@s uk-margin-auto-vertical' id='homeMainText'>CUSTOM TABLES <br></br> AND WOODWORKING</h1>
                        <h1 className='uk-hidden@s uk-margin-auto-vertical' id='homeMainText'>CUSTOM TABLES <br></br> AND WOOD<br></br>WORKING</h1>
                   </div>
                </div>
            </div>
          </div>

          <div id='servicesTopSection'> 
              <div  className='uk-container'>
                <div uk-grid='true' className='uk-visible@s'>
                    <div className='uk-width-1-1 '>
                        <ul class="uk-comment-meta uk-subnav uk-subnav-divider dirtybird  uk-margin-medium-top   ">
                            <li className='aboveGalleryServices '>Custom Tables</li>
                            <li id='' className='aboveGalleryServices '>Custom Metalworking </li>
                            <li className='aboveGalleryServices '>Floating Shelves</li>
                            <li className='aboveGalleryServices '>Mantels</li>                                                      
                        </ul>
                    </div>
                    <div className='uk-width-1-1 '>
                        <ul class="uk-comment-meta uk-subnav uk-subnav-divider dirtybird uk-margin-medium-bottom  ">                           
                            <li className='aboveGalleryServices '>Countertops</li>
                            <li className='aboveGalleryServices '>Metal Handrailings</li>
                            <li className='aboveGalleryServices '>Wood Art</li>                           
                        </ul>
                    </div>
                    
                </div>
                <div uk-grid='true' className='uk-hidden@s'>
                    <div className='uk-width-1-1 serviceMobileSPacing'>
                        <ul class="uk-comment-meta uk-subnav uk-subnav-divider dirtybirdmobile   uk-margin-small-top  ">
                            <li className='aboveGalleryServices '>Custom Tables</li>
                            <li id='' className='aboveGalleryServices '>Custom Metalworking</li>                                                                                 
                        </ul>
                    </div>
                    <div className='uk-width-1-1 serviceMobileSPacing'>
                        <ul class="uk-comment-meta uk-subnav uk-subnav-divider dirtybirdmobile    ">
                            <li className='aboveGalleryServices '>Floating Shelves</li>
                            <li id='' className='aboveGalleryServices '>Mantels</li>                                                                                 
                        </ul>
                    </div>                   
                    <div className='uk-width-1-1 serviceMobileSPacing'>
                        <ul class="uk-comment-meta uk-subnav uk-subnav-divider dirtybirdmobile  ">                           
                            <li className='aboveGalleryServices '>Countertops</li>
                            <li className='aboveGalleryServices '>Metal Handrailings</li>                                                     
                        </ul>
                    </div>
                    <div className='uk-width-1-1 serviceMobileSPacing'>
                        <ul class="uk-comment-meta uk-subnav uk-subnav-divider dirtybirdmobile  uk-margin-medium-bottom ">                                                      
                            <li className='aboveGalleryServices  '>Wood Art</li>                           
                        </ul>
                    </div>
                    
                </div>
              </div>
          </div>          

          <div id='homeWrapper2' className='uk-margin-large-top '>
            <div className='specialContainer'>
                <div className='uk-grid-match' uk-grid='true' uk-lightbox="animation: slide">
                      <div className='uk-width-1-1 uk-text-center uk-align-center uk-light'>
                        <h1 style={{fontWeight:'200'}} className='letterspaced'>GALLERY</h1>
                      </div>
                    <div className='uk-width-1-4@m uk-width-1-2@s uk-width-1-1 '>    
                        <a class="uk-inline" href="../images/Mayfly Woordworks Gallery_1.jpg" >
                            <img className='top8Pics' src="../images/Mayfly Woordworks Gallery_1.jpg" />
                        </a>                                     
                    </div>
                    <div className='uk-width-1-4@m uk-width-1-2@s uk-width-1-1 '>
                        <a class="uk-inline" href="../images/Mayfly Woordworks Gallery_2.jpg" >
                            <img className='top8Pics' src="../images/Mayfly Woordworks Gallery_2.jpg" />
                        </a> 
                    </div>
                    <div className='uk-width-1-4@m uk-width-1-2@s uk-width-1-1'>
                            <a class="uk-inline" href="../images/Mayfly Woordworks Gallery_3.jpg" >
                                <img className='top8Pics' src="../images/Mayfly Woordworks Gallery_3.jpg" />
                            </a> 
                    </div>
                    <div className='uk-width-1-4@m uk-width-1-2@s uk-width-1-1 '>
                            <a class="uk-inline" href="../images/Mayfly Woordworks Gallery_4.jpg" >
                                <img className='top8Pics' src="../images/Mayfly Woordworks Gallery_4.jpg" />
                            </a> 
                    </div>
                    <div className='uk-width-1-4@m uk-width-1-2@s uk-width-1-1 '>
                            <a class="uk-inline" href="../images/Mayfly Woordworks Gallery_5.jpg" >
                                <img className='top8Pics' src="../images/Mayfly Woordworks Gallery_5.jpg" />
                            </a> 
                    </div>
                    <div className='uk-width-1-4@m uk-width-1-2@s uk-width-1-1'>
                            <a class="uk-inline" href="../images/Mayfly Woordworks Gallery_6.jpg" >
                                <img className='top8Pics' src="../images/Mayfly Woordworks Gallery_6.jpg" />
                            </a> 
                    </div>
                    <div className='uk-width-1-4@m uk-width-1-2@s uk-width-1-1 '>
                            <a class="uk-inline" href="../images/Mayfly Woordworks Gallery_7.jpg" >
                                <img className='top8Pics' src="../images/Mayfly Woordworks Gallery_7.jpg" />
                            </a> 
                    </div>
                    <div className='uk-width-1-4@m uk-width-1-2@s uk-width-1-1 '>
                            <a class="uk-inline" href="../images/Mayfly Woordworks Gallery_8.jpg" >
                                <img className='top8Pics' src="../images/Mayfly Woordworks Gallery_8.jpg" />
                            </a> 
                    </div>
                    <div className='uk-width-1-4@m uk-width-1-2@s uk-width-1-1 '>
                             <a class="uk-inline" href="../images/Mayfly Woordworks Gallery_9.jpg" >
                                <img className='top8Pics' src="../images/Mayfly Woordworks Gallery_9.jpg" />
                            </a> 
                    </div>
                    <div className='uk-width-1-4@m uk-width-1-2@s uk-width-1-1'>
                             <a class="uk-inline" href="../images/Mayfly Woordworks Gallery_10.jpg" >
                                <img className='top8Pics' src="../images/Mayfly Woordworks Gallery_10.jpg" />
                            </a> 
                    </div>
                    <div className='uk-width-1-4@m uk-width-1-2@s uk-width-1-1 '>
                             <a class="uk-inline" href="../images/Mayfly Woordworks Gallery_11.jpg" >
                                <img className='top8Pics' src="../images/Mayfly Woordworks Gallery_11.jpg" />
                            </a> 
                    </div>
                    <div className='uk-width-1-4@m uk-width-1-2@s uk-width-1-1 '>
                             <a class="uk-inline" href="../images/Mayfly Woordworks Gallery_12.jpg" >
                                <img className='top8Pics' src="../images/Mayfly Woordworks Gallery_12.jpg" />
                            </a> 
                    </div>
                    <div className='uk-width-1-4@m uk-width-1-2@s uk-width-1-1 '>
                             <a class="uk-inline" href="../images/Mayfly Woordworks Gallery_13.jpg" >
                                <img className='top8Pics' src="../images/Mayfly Woordworks Gallery_13.jpg" />
                            </a> 
                    </div>
                    <div className='uk-width-1-4@m uk-width-1-2@s uk-width-1-1 '>
                             <a class="uk-inline" href="../images/Mayfly Woordworks Gallery_14.jpg" >
                                <img className='top8Pics' src="../images/Mayfly Woordworks Gallery_14.jpg" />
                            </a> 
                    </div>
                    <div className='uk-width-1-4@m uk-width-1-2@s uk-width-1-1 '>
                             <a class="uk-inline" href="../images/Mayfly Woordworks Gallery_15.jpg" >
                                <img className='top8Pics' src="../images/Mayfly Woordworks Gallery_15.jpg" />
                            </a> 
                    </div>
                    <div className='uk-width-1-4@m uk-width-1-2@s uk-width-1-1 '>
                             <a class="uk-inline" href="../images/Mayfly Woordworks Gallery_16.jpg" >
                                <img className='top8Pics' src="../images/Mayfly Woordworks Gallery_16.jpg" />
                            </a> 
                    </div>
                    <div className='uk-width-1-4@m uk-width-1-2@s uk-width-1-1 '>
                             <a class="uk-inline" href="../images\Mayfly_Website_New Gallery Images_1.jpg" >
                                <img className='top8Pics' src="../images\Mayfly_Website_New Gallery Images_1.jpg" />
                            </a> 
                    </div>
                    <div className='uk-width-1-4@m uk-width-1-2@s uk-width-1-1 '>
                             <a class="uk-inline" href="../images\Mayfly_Website_New Gallery Images_2.jpg" >
                                <img className='top8Pics' src="../images\Mayfly_Website_New Gallery Images_2.jpg" />
                            </a> 
                    </div>
                    <div className='uk-width-1-4@m uk-width-1-2@s uk-width-1-1 '>
                             <a class="uk-inline" href="../images\Mayfly_Website_New Gallery Images_3.jpg" >
                                <img className='top8Pics' src="../images\Mayfly_Website_New Gallery Images_3.jpg" />
                            </a> 
                    </div><div className='uk-width-1-4@m uk-width-1-2@s uk-width-1-1 '>
                             <a class="uk-inline" href="../images\Mayfly_Website_New Gallery Images_4.jpg" >
                                <img className='top8Pics' src="../images\Mayfly_Website_New Gallery Images_4.jpg" />
                            </a> 
                    </div><div className='uk-width-1-4@m uk-width-1-2@s uk-width-1-1 '>
                             <a class="uk-inline" href="../images\Mayfly_Website_New Gallery Images_5.jpg" >
                                <img className='top8Pics' src="../images\Mayfly_Website_New Gallery Images_5.jpg" />
                            </a> 
                    </div><div className='uk-width-1-4@m uk-width-1-2@s uk-width-1-1 '>
                             <a class="uk-inline" href="../images\Mayfly_Website_New Gallery Images_6.jpg" >
                                <img className='top8Pics' src="../images\Mayfly_Website_New Gallery Images_6.jpg" />
                            </a> 
                    </div><div className='uk-width-1-4@m uk-width-1-2@s uk-width-1-1 '>
                             <a class="uk-inline" href="../images\Mayfly_Website_New Gallery Images_7.jpg" >
                                <img className='top8Pics' src="../images\Mayfly_Website_New Gallery Images_7.jpg" />
                            </a> 
                    </div><div className='uk-width-1-4@m uk-width-1-2@s uk-width-1-1 '>
                             <a class="uk-inline" href="../images\Mayfly_Website_New Gallery Images_8.jpg" >
                                <img className='top8Pics' src="../images\Mayfly_Website_New Gallery Images_8.jpg" />
                            </a> 
                    </div><div className='uk-width-1-4@m uk-width-1-2@s uk-width-1-1 '>
                             <a class="uk-inline" href="../images\Mayfly_Website_New Gallery Images_9.jpg" >
                                <img className='top8Pics' src="../images\Mayfly_Website_New Gallery Images_9.jpg" />
                            </a> 
                    </div><div className='uk-width-1-4@m uk-width-1-2@s uk-width-1-1 '>
                             <a class="uk-inline" href="../images\Mayfly_Website_New Gallery Images_10.jpg" >
                                <img className='top8Pics' src="../images\Mayfly_Website_New Gallery Images_10.jpg" />
                            </a> 
                    </div><div className='uk-width-1-4@m uk-width-1-2@s uk-width-1-1 '>
                             <a class="uk-inline" href="../images\Mayfly_Website_New Gallery Images_11.jpg" >
                                <img className='top8Pics' src="../images\Mayfly_Website_New Gallery Images_11.jpg" />
                            </a> 
                    </div><div className='uk-width-1-4@m uk-width-1-2@s uk-width-1-1 '>
                             <a class="uk-inline" href="../images\Mayfly_Website_New Gallery Images_12.jpg" >
                                <img className='top8Pics' src="../images\Mayfly_Website_New Gallery Images_12.jpg" />
                            </a> 
                    </div><div className='uk-width-1-4@m uk-width-1-2@s uk-width-1-1 '>
                             <a class="uk-inline" href="../images\Mayfly_Website_New Gallery Images_13.jpg" >
                                <img className='top8Pics' src="../images\Mayfly_Website_New Gallery Images_13.jpg" />
                            </a> 
                    </div><div className='uk-width-1-4@m uk-width-1-2@s uk-width-1-1 '>
                             <a class="uk-inline" href="../images\Mayfly_Website_New Gallery Images_14.jpg" >
                                <img className='top8Pics' src="../images\Mayfly_Website_New Gallery Images_14.jpg" />
                            </a> 
                    </div><div className='uk-width-1-4@m uk-width-1-2@s uk-width-1-1 '>
                             <a class="uk-inline" href="../images\Mayfly_Website_New Gallery Images_15.jpg" >
                                <img className='top8Pics' src="../images\Mayfly_Website_New Gallery Images_15.jpg" />
                            </a> 
                    </div><div className='uk-width-1-4@m uk-width-1-2@s uk-width-1-1 '>
                             <a class="uk-inline" href="../images\Mayfly_Website_New Gallery Images_16.jpg" >
                                <img className='top8Pics' src="../images\Mayfly_Website_New Gallery Images_16.jpg" />
                            </a> 
                    </div><div className='uk-width-1-4@m uk-width-1-2@s uk-width-1-1 '>
                             <a class="uk-inline" href="../images\Mayfly_Website_New Gallery Images_17.jpg" >
                                <img className='top8Pics' src="../images\Mayfly_Website_New Gallery Images_17.jpg" />
                            </a> 
                    </div>
                </div>
            </div>
          </div>

          <div id='homeWrapper3' className='uk-margin-xlarge-top'>
            <div className='uk-container'>
                <div className='uk-grid-match' uk-grid='true'>
                    <div className='uk-width-1-2@m uk-width-1-1 uk-light uk-margin-auto-vertical'>
                        <div>
                            <h1 id='aboutmainText'className='uk-margin-large-bottom' >ABOUT MAYFLY</h1>
                            <hr className='servicesHr uk-margin-medium-bottom'></hr>
                            <p>
                                Here at Mayfly Woodworks we believe in deep rooting a connection between nature and 
                                our customers. By bringing a piece of nature into your home we believe it will 
                                strengthen that connection. We chose to work with trees because to us they are the 
                                ultimate symbol of life and allows us to thrive here on Earth. We travel all over 
                                the world to find trees that need to be removed then give them a new purpose and home. 
                                By going to these remote places, we find some of the most beautiful and exotic woods. 
                                <br></br>
                                <br></br>
                                Mayfly Woodworks was started by owner Tyler Volz and his wife Meghann Volz during their 
                                adventures outside. Their time spent out in nature is their most precious time and has 
                                brought them so much joy and appreciation. They wanted to find a way for people to 
                                experience and feel that same gratitude. They realized that the more humans connect with 
                                nature the more they appreciate our earth and thus can make an impact in protecting our 
                                planet.
                            </p>
                        </div>
                    </div>
                    <div className='uk-width-1-2@m uk-width-1-1 uk-visible@m '>
                        <img className='aboutMayMainPic uk-align-center'  src="../images/About Mayfly Image.jpg" />
                    </div>
                </div>
            </div>
          </div>

          <div id='homeWrapper4' className='uk-margin-xlarge-top'>
            <div className='' uk-grid='true'>
                <div  className='uk-width-1-1'>
                    <img id='homeMiddlePic' src="../images/drill close up.jpg"></img>
                </div>
            </div>
          </div>

          <div id='homeWrapper5' className='uk-margin-xlarge-top'>
            <div className='uk-container'>
                <div className='uk-grid-match' uk-grid='true'>
                    <div className='uk-width-1-4 '>
                        <img className='picsAboveWrapper6' src="../images/How It Works Icons_1.png" />
                    </div>
                    <div className='uk-width-1-4'>
                        <img className='picsAboveWrapper6' src="../images/How It Works Icons_2.png" />
                    </div>
                    <div className='uk-width-1-4'>
                        <img className='picsAboveWrapper6' src="../images/How It Works Icons_3.png" />
                    </div>
                    <div className='uk-width-1-4'>
                        <img className='picsAboveWrapper6' src="../images/How It Works Icons_4.png" />
                    </div>
                </div>
            </div>
          </div>

          <div id='homeWrapper6'  className='uk-margin-xlarge-top'>
            <div className='uk-container'>
                <div className='uk-grid-match uk-light' uk-grid='true'>
                      <div className='uk-width-1-1'>
                            <h1 id='HowItWorks'>HOW IT WORKS</h1>
                            <ul class="uk-comment-meta uk-subnav uk-subnav-divider dirtybird testers uk-margin-remove-top">
                                <li id='specialchange' className='letterspaced howLists'>DESIGN</li>
                                <li id='howlistsMobileChange' className='letterspaced howLists'>SPECIFIC SPACES</li>
                                <li className='letterspaced howLists'>PRICING</li>
                                <li className='letterspaced howLists'>WOODS</li>
                            </ul>
                      </div>
                      
                      <div className=' uk-width-1-1'>
                        <p>
                        Whether you know exactly what you are looking for or need help with designing something 
                        special, we are here to help. We go through the process of designing your project using 
                        3D modeling software so your vision can come to life. Then when the design is approved 
                        by you the hand crafting process begins. Once the build is complete, we will personally 
                        deliver and install your unique piece to ensure maximum quality. After that the piece is 
                        there for you and your family to enjoy for generations to come.
                        </p>
                      </div>
                     
                </div>
            </div>
          </div>

          <div id='homeWrapper7'  className='uk-margin-xlarge-top'>
            <div className='uk-container'>
                <div className='' uk-grid='true'>
                <div className='uk-width-expand uk-margin-auto-vertical uk-text-center'>
                    </div>
                    <div className='uk-width-4-5@m uk-width-1-1  uk-text-center'>  
                                          
                        <div>
                            <hr style={{color:'white', borderTop:'20px solid', width:'100px'}} className=' uk-align-center'></hr>
                            <span id='homeQuotes' uk-icon="icon: quote-right; ratio: 6"></span>
                            <p className='carouselText' style={{fontSize:'25px',color:'white'}}>
                                To build something by hand is an incredible task.
                                An original piece presents beautiful challenges 
                                and a way to preserve an expression of the soul.
                                The level of skill involved will only be known                                                                                                                                    
                                to those that build……………and here, we build.
                            </p>
                            <h3 style={{color:'white'}}><b>-Mayfly Woodworks Motto</b></h3>
                        </div>
                                    

                    </div>
                    <div className='uk-width-expand uk-margin-auto-vertical uk-text-center'>                
                    </div>
                </div>
            </div>
          </div>

          <div id='homeWrapper8'  className='uk-margin-xlarge-top uk-margin-xlarge-bottom'>
            <div className='uk-container'>
                <div className='' uk-grid='true'>
                <div className='uk-width-1-1 uk-text-center uk-light'>
                    <h1  id='CRAFTSMAN' className='uk-margin-large-bottom'>WHO WE ARE</h1>
                </div>
                <div className='uk-width-1-1 uk-width-1-2@s  uk-light cratfsmenDivs1'>
                    <img className='craftsmenPics ' src="../images/About Us Image_Tyler.jpg" />
                    <br></br>
                    <span className='uk-margin-small-top' uk-icon="icon: triangle-up; ratio: 2"></span>
                    <h1 className='uk-margin-remove-bottom'>Tyler Volz</h1>
                    <h3 className='uk-margin-remove-top'>Owner/Craftsman</h3>
                    <hr className='craftsmanHR '></hr>
                    <p>
                    Tyler comes from a long history of craftsman in the family and is a 5th generation 
                    woodworker. He grew up building with his father and grandfather from a young age. 
                    Each year growing up Tyler would build gifts for his family with his grandfather. 
                    The builds would get more and more advanced and this is where Tyler progressed his 
                    skills. When Tyler is not in the shop building, you can find him pursuing one of 
                    his many passions. He is an avid mountain biker and enjoys the outdoors and camping 
                    with his wife. He also enjoys working on cars along with restoring his grandfathers 
                    1978 Honda dirtbike. If he is not investing his down time outside or fixing up things, 
                    he is playing his acoustic guitar. 
                       
                    </p>
                   
                </div>
                <div className='uk-width-1-1 uk-width-1-2@s  uk-light cratfsmenDivs2'>
                    <img className='craftsmenPics' src="../images/About Us Image_Meghan_NEW.jpg" />
                    <br></br>
                    <span className='uk-margin-small-top' uk-icon="icon: triangle-up; ratio: 2"></span>
                    <h1 className='uk-margin-remove-bottom'>Meghann Volz</h1>
                    <h3 className='uk-margin-remove-top'>Co-Owner/Business Coordinator </h3>
                    <hr className='craftsmanHR '></hr>
                    <p>
                        Meghann runs the social media and administrative work in the business. 
                        She occasionally gets to step in and help Tyler with designing and building 
                        the projects. Her favorite part when she is in the shop is sanding and 
                        staining. When Meghann is not working she loves to travel or be outside 
                        in nature. She is an avid mountain biker and has fun hitting the trails with 
                        Tyler. Meghann also spends her time swimming in mountain lakes, running and teaching yoga. 
                       
                    </p>
                   
                </div>
               

                </div>
            </div>
          </div>

           <div id='homeWrapper10' className='' >
            <div className='' uk-grid='true' className=' '>
                <div className='uk-width-1-2@m uk-width-1-1 '>
                   <div className='uk-container  '>
                       <h1 className='uk-margin-xlarge-top' id='bowlMainText'>TASTEFUL<br></br> BOWLS</h1>
                       <p className='bowlPText' style={{color:'black'}}>
                            Tyler’s Grandfather Bill Volz is an expert woodturner, and has a strong passion for turning. He makes 
                            all the bowls for Mayfly Woodworks. If you are interested in him making you a bowl or any turning 
                            project out of wood contact us at the bottom of the page.
                        </p>                       
                   </div>
                </div>
            </div>
          </div>

           <div id='homeWrapper11' className='uk-margin-xlarge-top uk-margin-xlarge-bottom' >
            <div className=''>
                <div uk-grid='true'>
                <div className='uk-width-1-1  uk-light uk-margin-medium-bottom'>
                        <h2 className='uk-text-center' id='mayflytablesText'>@mayflywoodworks</h2>
                        </div>
                </div>
                <div className='homeWrapper11' uk-grid='true' className='uk-grid-match '>
                        <div className='uk-width-1-5@m uk-width-1-2@s uk-width-1-1'>
                            <img className='mayflytablePics' src="../images/Mayfly Gallery_1.jpg" />
                        </div>
                        <div className=' uk-width-1-5@m uk-width-1-2@s uk-width-1-1'>
                            <img className='mayflytablePics'  src="../images/Mayfly Gallery_2.jpg" />
                        </div>
                        <div className='uk-width-1-5@m uk-width-1-2@s uk-width-1-1'>
                            <img className='mayflytablePics'  src="../images/Mayfly Gallery_3.jpg" />
                        </div>
                        <div className=' uk-width-1-5@m uk-width-1-2@s uk-width-1-1'>
                            <img className='mayflytablePics'  src="../images/Mayfly Gallery_4.jpg" />
                        </div>
                        <div className='uk-width-1-5@m uk-width-1-2@s uk-width-1-1'>
                            <img className='mayflytablePics' src="../images/Mayfly Gallery_5.jpg" />
                        </div>
                    </div>
            </div>
          </div>

          <div id='homeWrapper9'  >
            <div className='uk-container'>
                <div className='uk-grid-match' uk-grid='true'>
                    <div className='uk-width-1-2@m uk-width-1-1'>
                        <h1 className='uk-margin-large-top uk-margin-medium-bottom uk-text-center' id='contactText'><b>CONTACT US</b></h1>
                      
                    </div>
                    <div className='uk-width-1-2@m uk-width-1-1 uk-margin-large-top uk-margin-medium-bottom'>
                        <p className='uk-margin-auto-vertical'><b>          
                        Send us a message with some details regarding your project. We are excited to work with you! 
                            </b></p>                    
                    </div>
                    <div className='uk-width-1-1 '>
                        <div className="formDiv ">
                            <form>
                                <fieldset className="uk-fieldset ">
                                <div className="uk-margin">
                                    <div id="emailInput" className="uk-inline uk-width-1-2@m uk-width-1-1">                               
                                    <input
                                    value={this.state.name}
                                    onChange={this.handleInputChange}
                                    name='name'
                                        className="uk-input ContactInputBody"
                                        type="text"
                                        placeholder="Name" />
                                    </div>
                                    <div  id='formLastName' className="uk-inline uk-width-1-2@m uk-width-1-1 formLastName">                                 
                                    <input
                                    value={this.state.email}
                                    onChange={this.handleInputChange}
                                    name='email'
                                        className="uk-input ContactInputBody"
                                        type="text"
                                        placeholder="Email" />
                                    </div>
                                </div>
                                <div className="uk-margin">
                                    <div id="emailInput" className="uk-inline uk-width-1-1 ">                         
                                    <input
                                    value={this.state.subject}
                                    onChange={this.handleInputChange}
                                    name='subject'
                                        className="uk-input ContactInputBody"
                                        type="text"
                                        placeholder="Subject" />
                                    </div>
                                   
                                </div>
                            

                                <div id="textInput" className="uk-margin uk-width-1-1">

                                    <textarea
                                    value={this.state.message}
                                    onChange={this.handleInputChange}
                                    name='message'
                                    className="uk-textarea ContactInputBody"
                                    rows="5"
                                    placeholder="Message">
                                    </textarea>
                                </div>
                                </fieldset>
                            </form>
                            <img className='uk-margin-medium-top' style={{width:'150px', height:'50px'}} src='../images/Mayfly Submit Button_LARGER.svg' onClick={this.sendEmail}></img>
                            </div>
                            <ToastContainer position="bottom-center" autoClose={4000} />
                    </div>
                </div>
                
            </div>
                <div className='uk-margin-xlarge-top footerBody'>
                    <Footer></Footer>
                </div>
          </div>
      </div>
    );
  }
}


export default Home;