import React, { Component } from 'react';
import { Route, Router, Switch } from "react-router-dom";

import Header from './Components/Header.js'


import NotFound from './Pages/NotFound.js'
import Home from './Pages/Home.js'
import History from './Pages/History/History.js'

class App extends Component {
  render() {
    return (
      <Router  history={History}>
         <div className="App">
        <Header></Header>
          
            <Switch>
              <Route exact path="/" render={props =><Home/>} />
              <Route render={props =><NotFound/>}/>
              
            </Switch>
        </div>
      </Router>
    );
  }
}

export default App;
